import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'

const initialState = {
  applicationFilter: {
    name: null,
    school: {},
    instrument: {},
    startDate: null,
    endDate: null,
    creationYear: {},
    status: null,
    registrationDate: null,
    regional: null,
  },
  classroomFilter: {
    classroom: {},
    school: {},
    shift: {},
    schoolYear: {},
    grade: {},
  },
  classroomNewFilter: {
    name: '',
    teachingNetworks: [],
    schools: [],
    classrooms: [],
    grades: [],
    schoolYears: [],
  },
  studentFilter: {
    classroom: {},
    name: '',
    school: {},
    shift: {},
    schoolYear: {},
    grade: {},
  },
  studentActualFilter: {
    name: '',
    teachingNetworks: [],
    schools: [],
    classrooms: [],
    grades: [],
    schoolYears: [],
  },
  teacherFilter: {
    classroom: {},
    name: '',
    school: {},
  },
  teacherActualFilter: {
    name: '',
    teachingNetworks: [],
    schools: [],
    classrooms: [],
  },
  classLevel: {
    city: null,
    schools: [],
    frequencyId: null,
    frequencyItemId: null,
    status: null,
  },
  classLevelManual: {
    city: null,
    schools: [],
    classroom: null,
    program: null,
    frequencyId: null,
    frequencyItemId: null,
    status: null,
  },
  filter_regionals_schools: {
    teachingNetworks: [],
    regional: '',
    states: [],
    cities: [],
    schools: [],
  },
  filter_coordinators_regional: {
    name: '',
    regionals: [],
    teachingNetworks: [],
    situation: null,
  },
  filter_school_manager: {
    name: '',
    teachingNetworks: [],
    regionals: [],
    schools: [],
    situation: null,
  },
  filter_teacher: {
    name: '',
    schools: [],
    classrooms: [],
    teachingNetworks: [],
  },
  filter_classrom: {
    name: '',
    teachingNetworks: [],
    schools: [],
    classrooms: [],
    grades: [],
    schoolYears: [],
  },
  filter_studant: {
    name: '',
    teachingNetworks: [],
    schools: [],
    classrooms: [],
    grades: [],
    schoolYears: [],
  },
  filter_assessment: {
    school: [],
    instrument: [],
    startDate: null,
    endDate: null,
    creationYear: [],
    situation: null,
    status: [],
    regiona: [],
    teachingNetwork: [],
    unityType: [],
    partner: [],
    partnersSchool: [],
  },
  filter_programs: {
    schools: [],
    classrooms: [],
    teachingNetworks: [],
    grades: [],
    year: [],
    status: null,
  },
}

export const FilterContext = createContext({ ...initialState })

export default function FilterContextProvider({ children }) {
  const [filters, setFiltersState] = useState(initialState)

  function resetFilterState() {
    setFiltersState(initialState)
  }

  return (
    <FilterContext.Provider value={{ filters, setFiltersState, resetFilterState }}>
      {children}
    </FilterContext.Provider>
  )
}

FilterContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}
